import isEqual from "lodash/isEqual";

import { serializeId } from "../../services/references";

import { Entity, GenericState, Redoable } from "./redoableSliceFactory";
import { selectEntity } from "./redoableSliceSelectors";

export const addEntity = <T extends Entity>(
  state: Redoable<T>[],
  entity: T
): void => {
  state.push({
    past: [],
    present: entity,
    future: [],
  });
};

export const deleteEntities = <T extends Entity>(
  state: GenericState<T>,
  ids: string[]
): void => {
  ids.forEach((id) => {
    const index = state.findIndex((e) => e.present.id === id);

    if (index !== -1) {
      state.splice(index, 1);
    }
  });
};

export const removeEntity = <T extends Entity>(
  state: GenericState<T>,
  id: string
): GenericState<T> => state.filter((entity) => entity.present.id !== id);

export const removeAllEntities = <T extends Entity>(
  state: GenericState<T>,
  idToExclude?: string
): GenericState<T> => {
  if (idToExclude) {
    const excludedEntity = state.find(
      (entity) => entity.present.id === idToExclude
    );
    return excludedEntity ? [excludedEntity] : [];
  }

  return [];
};

export const removeEntitiesInFolderByPath = <T extends Entity>(
  state: GenericState<T>,
  folderEntityPath: string | undefined
): GenericState<T> => {
  if (!folderEntityPath) return state;
  return state.filter((entity) => {
    const entityPath = serializeId(entity.present.id);
    const isEntityInsideFolder =
      entityPath && entityPath.startsWith(folderEntityPath);
    return !isEntityInsideFolder;
  });
};

export const removeEntitiesInFolder = <T extends Entity>(
  state: GenericState<T>,
  folderEntityId: string
): GenericState<T> =>
  removeEntitiesInFolderByPath(state, serializeId(folderEntityId));

export const updateEntity = <T extends Entity>(
  state: GenericState<T>,
  newEntity: T
): Redoable<T> | void => {
  const entity = state.find((entity) => entity.present.id === newEntity.id);

  if (entity === undefined || isEqual(newEntity, entity.present)) {
    return;
  }

  entity.past = [...entity.past, entity.present];
  entity.present = newEntity;
  entity.future = [];

  return entity;
};

export const updateEntityOmitHistory = <T extends Entity>(
  state: GenericState<T>,
  newEntity: T
): void => {
  const entity = state.find((entity) => entity.present.id === newEntity.id);

  if (entity === undefined || isEqual(newEntity, entity.present)) {
    return;
  }

  entity.present = newEntity;
  entity.future = [];
};

export const updateEntityDraft = <T extends Entity>(
  state: GenericState<T>,
  data: { entityId: string; draftValue: string }
): void => {
  const { entityId, draftValue } = data;
  const entity = state.find((entity) => entity.present.id === entityId);

  if (entity) {
    entity.draft = draftValue;
  }
};

export const updateEntityWithDraft = <T extends Entity>(
  state: GenericState<T>,
  newEntity: T,
  draftValue: string
): void => {
  const updatedEntity = updateEntity(state, newEntity);

  if (updatedEntity) {
    updatedEntity.draft = draftValue;
  }
};

export const undoEntity = <T extends Entity>(
  state: GenericState<T>,
  id: string
): void => {
  const entity = selectEntity(state as GenericState<T>, id);

  if (!entity) {
    return;
  }

  const present = entity.past.pop();

  if (!present) {
    return;
  }

  entity.future = [entity.present, ...entity.future];
  entity.present = present;
};

export const redoEntity = <T extends Entity>(
  state: GenericState<T>,
  id: string
): void => {
  const entity = selectEntity(state as GenericState<T>, id);

  if (!entity) {
    return;
  }

  const present = entity.future.shift();

  if (!present) {
    return;
  }

  entity.past = [...entity.past, entity.present];
  entity.present = present;
};

export const markEntityDraftAsValid = <T extends Entity>(
  state: GenericState<T>,
  id: string
): void => {
  const entity = selectEntity(state as GenericState<T>, id);

  if (!entity) {
    return;
  }

  entity.isDraftValid = true;
};

export const markEntityDraftAsInvalid = <T extends Entity>(
  state: GenericState<T>,
  id: string
): void => {
  const entity = selectEntity(state as GenericState<T>, id);

  if (!entity) {
    return;
  }

  entity.isDraftValid = false;
};

export const refreshEntities = <T extends Entity>(
  state: GenericState<T>,
  refreshedEntities: Record<string, { entity: T; draft?: string } | null>
): void => {
  // identify the entities to be deleted
  const idsToDelete: string[] = [];
  state.forEach((e) => {
    if (!refreshedEntities[e.present.id]) {
      idsToDelete.push(e.present.id);
    }
  });

  deleteEntities(state, idsToDelete);

  Object.entries(refreshedEntities).forEach(([id, updatedEntity]) => {
    if (!updatedEntity?.entity) {
      return;
    }

    const presentEntity = state.find((e) => e.present.id === id);

    if (presentEntity) {
      presentEntity.draft
        ? updateEntityWithDraft(
            state,
            updatedEntity.entity as Entity,
            updatedEntity.draft ?? ""
          )
        : updateEntity(state, updatedEntity.entity as Entity);
      return;
    }

    addEntity(state, updatedEntity.entity as Entity);
  });
};
