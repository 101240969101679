/**
 * The indexer has information about usage of entities
 * For eg:
 * some flow is used in other flows or apis or schedulers
 * Sample value stored in indexes is as below:
 *
 * {
 *
 * "idOfFlow":{
 *      flows: <"someotherflowid">,
 *      apis: <"someapiid">
 *      schedulers: <"someschedulerid">
 *     }
 *  }
 */

export type FileIndex = Partial<Record<IndexableEntityTypes, Set<string>>>;

interface Indexes {
  [key: string]: FileIndex;
}

const indexes: Indexes = {};

export type IndexableEntityTypes =
  | "flows"
  | "schedulers"
  | "apis"
  | "virtualSteps";

export const modifyEntityIndex = (
  usedId: string,
  usedBy: string,
  usedInEntityType: IndexableEntityTypes,
  existingIndex: FileIndex | undefined
): FileIndex | undefined => ({
  ...existingIndex,
  [usedInEntityType]: (
    existingIndex?.[usedInEntityType] ?? new Set<string>()
  ).add(usedBy),
});

export const removeUsageOfEntity = (
  entityId: string,
  entityType: IndexableEntityTypes
): void => {
  Object.keys(indexes).forEach((id) => {
    indexes[id]?.[entityType]?.delete(entityId);
  });
};

export const updateEntityIndexes = (
  usedId: string,
  usedBy: string,
  usedInEntityType: IndexableEntityTypes
): void => {
  const modifiedIndex = modifyEntityIndex(
    usedId,
    usedBy,
    usedInEntityType,
    getEntityIndexes(usedId)
  );

  if (modifiedIndex) {
    indexes[usedId] = modifiedIndex;
  }
};

export const getEntityIndexes = (entityId: string): FileIndex | undefined =>
  indexes[entityId];
