import { Stats } from "fs";

import { FileNode, FileType, Folder } from "../model/file";

import { isDir } from "./fs-utils";
import { deserializeId } from "./references";
import { getWorkspaceFS, path } from "./workspace";

export const getNodePath = (
  nodeId: string | undefined,
  nodes: Array<FileNode>,
  parentNodes?: Array<FileNode>
): Array<FileNode> | undefined => {
  if (parentNodes === undefined) {
    parentNodes = [];
  }

  for (const node of nodes) {
    if (node.id === nodeId) {
      return [
        ...parentNodes,
        {
          ...node,
          children: [],
        },
      ];
    }

    const nodePath = getNodePath(nodeId, node.children, [
      ...parentNodes,
      {
        ...node,
        children: [],
      },
    ]);

    if (nodePath !== undefined) {
      return nodePath;
    }
  }

  return undefined;
};

export const findNode = (
  nodeId: string,
  nodes: Array<FileNode>
): FileNode | undefined => {
  for (const node of nodes) {
    if (node.id === nodeId) {
      return node;
    }

    const found = findNode(nodeId, node.children);

    if (found !== undefined) {
      return found;
    }
  }

  return undefined;
};

export const mapFileNode = (
  id: string,
  text: string,
  nodeType: FileType = FileType.File
): FileNode => ({
  absolutePath: "",
  children: [],
  date: "",
  displayName: text,
  id: id,
  path: "",
  type: nodeType,
});

export const flattenFolder = (folder: Folder): Array<FileNode> => {
  const allFileNodes = [];

  for (const node of folder) {
    allFileNodes.push(node);
    allFileNodes.push(...getAllChildNodes(node));
  }

  return allFileNodes;
};

export const getOnlyFilesInFolder = (folder: Folder): Array<FileNode> =>
  folder.filter(({ type }) => type === FileType.File);

export const getFolderFileNodes = (folder: Folder): Array<FileNode> =>
  getOnlyFilesInFolder(flattenFolder(folder));

export const getFolderFileNodeIds = (folder?: Folder): Array<string> =>
  getFolderFileNodes(folder || []).map(({ id }) => id);

export const getAllChildNodes = (
  node: FileNode | undefined,
  filesOnly = false
): Array<FileNode> => {
  if (node === undefined) {
    return [];
  }

  return node.children.reduce<Array<FileNode>>((result, child) => {
    if (child.type === FileType.Directory && filesOnly) {
      return [...result, ...getAllChildNodes(child, filesOnly)];
    }

    return [...result, child, ...getAllChildNodes(child, filesOnly)];
  }, []);
};

export const loadNodes = async (
  contentRoot: string,
  location: string,
  filesOnly = false,
  filePath?: string
): Promise<Array<FileNode>> => {
  const fs = getWorkspaceFS();

  const result: Array<FileNode> = [];

  if (!(await isDir(location))) {
    return result;
  }

  const nodes = (await fs.promises.readdir(location)) as Array<string>;

  for (const node of nodes) {
    const absolutePath = path.join(location, node);
    const directory = await isDir(absolutePath);

    if (filesOnly && directory) {
      continue;
    }

    const nodeStats = await getNodeStats(absolutePath);

    const fullPath = filePath === undefined ? node : path.join(filePath, node);
    const children = await loadNodes(
      contentRoot,
      absolutePath,
      filesOnly,
      fullPath
    );

    result.push({
      id: deserializeId(contentRoot, fullPath),
      absolutePath,
      path: fullPath,
      children,
      type: directory ? FileType.Directory : FileType.File,
      get displayName() {
        if (this.type === FileType.Directory) {
          return path.basename(this.path);
        }

        return path.basename(this.path, path.extname(this.path));
      },
      date: getBirthDate(nodeStats),
    });
  }

  return result;
};

const getNodeStats = async (location: string): Promise<Stats | undefined> => {
  const fs = getWorkspaceFS();

  try {
    return (await fs.promises.stat(location)) as Stats;
  } catch (error) {
    return undefined;
  }
};

const getBirthDate = (nodeStats?: Stats) => {
  if (nodeStats === undefined || nodeStats.birthtime === undefined) {
    return new Date().toLocaleDateString();
  }

  return nodeStats.birthtime.toLocaleDateString();
};
