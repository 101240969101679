import { Schema, SerializedSchema } from "../model/schemas";
import { Workspace } from "../model/workspace";
import { nameSelector } from "../store/schemas/selectors";

import {
  DeleteEntity,
  GetEntity,
  MoveEntity,
  SaveEntity,
} from "./entityService.types";
import {
  deleteFile,
  getFileDisplayName,
  moveFile,
  readFile,
  saveFile,
} from "./fs-utils";
import { getDeserializedId } from "./references";
import { getContentRoot } from "./workspace";

export const getSchema: GetEntity<Schema> = async (id, workspace) => {
  try {
    const file = (await readFile(id, workspace, "schemas")) as SerializedSchema;
    const fileName = getFileDisplayName(id, workspace, "schemas");
    return deserializeSchema(id, fileName, file);
  } catch (e) {
    console.error(e);
    throw new Error(`Schema with the id "${id}" can not be retrieved`);
  }
};

export const getSchemaId = async (
  path: string,
  workspace: Workspace
): Promise<string> => {
  const id = getDeserializedId(getContentRoot(workspace, "schemas"), path);

  if (id === undefined) {
    throw new Error(`Schema with the path "${path}" can not be retrieved`);
  }

  return id;
};

export const saveSchema: SaveEntity<Schema> = async (
  schema,
  workspace,
  targetPath
) => {
  const name = nameSelector(schema);

  try {
    const serialized = serializeSchema(schema);

    await saveFile(
      schema.id,
      workspace,
      "schemas",
      serialized,
      name,
      targetPath
    );

    return schema;
  } catch (e) {
    console.error(e);
    throw new Error(`Schema "${name}" can not be saved`);
  }
};

export const moveSchema: MoveEntity<Schema> = async (
  id,
  workspace,
  targetPath
) => {
  try {
    await moveFile(id, workspace, "schemas", targetPath);
  } catch (e) {
    console.error(e);
    throw new Error(`Schema with the id "${id}" can not be moved`);
  }
};

export const deleteSchema: DeleteEntity = async (id, workspace) => {
  try {
    await deleteFile(id, workspace, "schemas");
  } catch (e) {
    console.error(e);
    throw new Error(`Schema with the id "${id}" can not be deleted`);
  }
};

export const deserializeSchema = (
  id: string,
  name: string,
  file: SerializedSchema
): Schema => ({
  id,
  fileName: name,
  file,
});

export const serializeSchema = ({ file }: Schema): SerializedSchema => file;
