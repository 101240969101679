import { VirtualStep } from "../../model/virtualSteps";
import { createRedoableSlice } from "../utils/redoableSliceFactory";

import {
  deleteVirtualStepsAction,
  deleteVirtualStepsFolderAction,
  openVirtualStepAction,
  overwriteVirtualStepsFolderAction,
  refreshVirtualStepsAction,
  storeVirtualStepsAction,
} from "./actions";
import { name } from "./sliceName";

const virtualStepsSlice = createRedoableSlice<VirtualStep>({
  name,
  reducers: {},
  predefinedThunks: {
    openEntityAction: openVirtualStepAction,
    refreshEntitiesAction: refreshVirtualStepsAction,
    storeEntityAction: storeVirtualStepsAction,
    deleteEntityAction: deleteVirtualStepsAction,
    deleteFolderAction: deleteVirtualStepsFolderAction,
    overwriteFolderAction: overwriteVirtualStepsFolderAction,
  },
});

export const { update, add, remove, redo, undo } = virtualStepsSlice.actions;

export default virtualStepsSlice.reducer;
