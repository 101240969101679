import {
  ActionCreatorWithPayload,
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from "@reduxjs/toolkit";

import { updateWorkspaceAction } from "../workspaces/updateWorkspaceAction";

import {
  DeleteEntityAction,
  DeleteFolderAction,
  OpenEntityAction,
  RefreshEntitiesAction,
  RenameFolderAction,
  StoreEntityAction,
} from "./actionFactory";
import {
  addEntity,
  markEntityDraftAsInvalid,
  markEntityDraftAsValid,
  redoEntity,
  refreshEntities,
  removeAllEntities,
  removeEntitiesInFolder,
  removeEntitiesInFolderByPath,
  removeEntity,
  undoEntity,
  updateEntity,
  updateEntityDraft,
  updateEntityOmitHistory,
} from "./redoableSliceMutators";
import { selectEntity } from "./redoableSliceSelectors";

export type Entity = { id: string };

export interface Redoable<T extends Entity> {
  past: Array<T>;
  present: T;
  future: Array<T>;
  draft?: string;
  isDraftValid?: boolean;
}

export type GenericState<T extends Entity> = Array<Redoable<T>>;

type ExtraReducersHelper<T extends Entity> = (
  builder: ActionReducerMapBuilder<GenericState<T>>
) => void;

type PredefinedThunks<T extends Entity> = {
  refreshEntitiesAction: RefreshEntitiesAction<T>;
  openEntityAction: OpenEntityAction<T>;
  loadEntityAction: OpenEntityAction<T>;
  storeEntityAction: StoreEntityAction<T>;
  deleteEntityAction: DeleteEntityAction;
  deleteFolderAction: DeleteFolderAction;
  overwriteFolderAction: RenameFolderAction;
  closeEntityTabAction: ActionCreatorWithPayload<string, string>;
};

type GenericSliceOptions<
  T extends Entity,
  Reducers extends SliceCaseReducers<GenericState<T>>
> = {
  name: string;
  initialState?: GenericState<T>;
  reducers: ValidateSliceCaseReducers<GenericState<T>, Reducers>;
  extraReducers?: ExtraReducersHelper<T>;
  predefinedThunks?: Partial<PredefinedThunks<T>>;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function createRedoableSlice<
  T extends Entity,
  Reducers extends SliceCaseReducers<GenericState<T>> = SliceCaseReducers<
    GenericState<T>
  >
>({
  name,
  initialState = [],
  reducers,
  extraReducers,
  predefinedThunks,
}: GenericSliceOptions<T, Reducers>) {
  return createSlice({
    name,
    initialState: initialState,
    reducers: {
      update(state: GenericState<T>, action: PayloadAction<T>) {
        updateEntity(state, action.payload);
      },
      updateOmitHistory(state: GenericState<T>, action: PayloadAction<T>) {
        updateEntityOmitHistory(state, action.payload);
      },
      updateDraft(
        state: GenericState<T>,
        action: PayloadAction<{ entityId: string; draftValue: string }>
      ) {
        updateEntityDraft(state, action.payload);
      },
      add(state: GenericState<T>, action: PayloadAction<T>) {
        addEntity(state, action.payload);
      },
      removeAll(
        state: GenericState<T>,
        action: PayloadAction<string | undefined>
      ) {
        return removeAllEntities(state, action.payload);
      },
      remove(state: GenericState<T>, action: PayloadAction<string>) {
        return removeEntity(state, action.payload);
      },
      undo(state: GenericState<T>, action: PayloadAction<string>) {
        undoEntity(state, action.payload);
      },
      redo(state: GenericState<T>, action: PayloadAction<string>) {
        redoEntity(state, action.payload);
      },
      markDraftAsValid(state: GenericState<T>, action: PayloadAction<string>) {
        markEntityDraftAsValid(state, action.payload);
      },
      markDraftAsInvalid(
        state: GenericState<T>,
        action: PayloadAction<string>
      ) {
        markEntityDraftAsInvalid(state, action.payload);
      },
      ...reducers,
    },
    extraReducers: (builder) => {
      // clear redux slices when workspace is updated with git pull
      builder.addCase(updateWorkspaceAction.fulfilled, () => []);

      if (extraReducers) {
        extraReducers(builder);
      }

      addPredefinedThunkHandling(predefinedThunks, builder);
    },
  });
}

function addPredefinedThunkHandling<T extends Entity>(
  predefinedThunks: Partial<PredefinedThunks<T>> | undefined,
  builder: ActionReducerMapBuilder<GenericState<T>>
) {
  if (!predefinedThunks) {
    return;
  }

  const {
    openEntityAction,
    loadEntityAction,
    storeEntityAction,
    deleteEntityAction,
    deleteFolderAction,
    closeEntityTabAction,
    refreshEntitiesAction,
    overwriteFolderAction,
  } = predefinedThunks;

  if (refreshEntitiesAction) {
    builder.addCase(refreshEntitiesAction.fulfilled, (state, action) => {
      refreshEntities(
        state,
        action.payload as Record<
          string,
          { entity: Entity; draft?: string } | null
        >
      );
    });
  }

  if (storeEntityAction) {
    builder.addCase(storeEntityAction.fulfilled, (state, action) => {
      const existing = selectEntity(state, action.payload.id);

      if (existing) {
        updateEntity(state, action.payload as Entity);
      } else {
        addEntity(state, action.payload as Entity);
      }
    });
  }

  if (deleteEntityAction) {
    builder.addCase(deleteEntityAction.fulfilled, (state, action) =>
      removeEntity(state, action.meta.arg)
    );
  }

  if (deleteFolderAction) {
    builder.addCase(deleteFolderAction.fulfilled, (state, action) =>
      removeEntitiesInFolder(state, action.meta.arg)
    );
  }

  if (overwriteFolderAction) {
    // Pending state (not fulfilled) is necessary, otherwise newly moved
    // flows will be closed as well
    builder.addCase(overwriteFolderAction.pending, (state, action) =>
      removeEntitiesInFolderByPath(state, action.meta.arg.newName)
    );
  }

  if (closeEntityTabAction) {
    builder.addCase(closeEntityTabAction, (state, action) =>
      removeEntity(state, action.payload)
    );
  }

  if (openEntityAction) {
    builder.addCase(openEntityAction.fulfilled, (state, action) => {
      const existing = selectEntity(state, action.payload.id);
      !existing && addEntity(state, action.payload as Entity);
    });
  }

  if (loadEntityAction) {
    builder.addCase(loadEntityAction.fulfilled, (state, action) => {
      const existing = selectEntity(state, action.payload.id);
      !existing && addEntity(state, action.payload as Entity);
    });
  }
}
