export const closeWindowAfterMovingTokenQuery = (): void => {
  const token = getTokenFromQueryParameter();
  if (token) setTokenToLocalStorage(token);
  window.close();
};

export const closeWindowAfterRemovingToken = (): void => {
  clearTokenFromLocalStore();
  window.close();
};

export const isTokenInQuery = (): boolean => !!getTokenFromQueryParameter();

function setTokenToLocalStorage(token: string) {
  localStorage.setItem("Authorization", token);
}

export const getTokenFromLocalStorage = (): string | null =>
  localStorage.getItem("Authorization");

export const clearTokenFromLocalStore = (): void =>
  localStorage.removeItem("Authorization");

export const getTokenFromQueryParameter = (): string | null =>
  new URLSearchParams(window.location.search).get("token");

export const isLogoutAction = (): boolean =>
  new URLSearchParams(window.location.search).get("action") === "logout";
