import { useDispatch } from "react-redux";

import { addNotification } from "../../store/designer/actions";
import { refreshAndSetupWorkspaceAction } from "../../store/workspaces/actions";

export function useWorkspaceRefresh(): {
  handleRefresh: () => void;
} {
  const dispatch = useDispatch();

  const handleRefresh = async () => {
    await dispatch(refreshAndSetupWorkspaceAction());
    dispatch(
      addNotification({ message: "Workspace is updated", variant: "success" })
    );
  };

  return {
    handleRefresh,
  };
}
