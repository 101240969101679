import { createSelector } from "@reduxjs/toolkit";

import { VirtualStep } from "../../model";
import { FileType, Folder } from "../../model/file";
import { split } from "../../services/fs-utils";
import {
  findNode,
  getFolderFileNodes,
  getOnlyFilesInFolder,
} from "../../services/nodes";
import { mapFileNode } from "../../services/nodes";
import { serializeId } from "../../services/references";
import { generatedDimHexColor } from "../../utils/generateDimHexColor";
import { ALL_NODES_ID, ROOT_NODE_ID } from "../designer/constants";
import { StepTypeEntityModel } from "../steps-schemas/selectors";
import { omitExtension } from "../utils/path";
import { GenericState, Redoable } from "../utils/redoableSliceFactory";
import { selectEntity } from "../utils/redoableSliceSelectors";
import { selectFolder, selectNodes } from "../workspaces/selectors";
import { RootState } from "..";

export const selectVirtualStepsFolder = (state: RootState): Folder =>
  selectFolder(state, "virtualSteps");

export const nameSelector = (virtualStep: VirtualStep): string =>
  virtualStep.fileName;

export const selectVirtualStepPresent = (
  state: RootState,
  virtualStepId: string
): VirtualStep | undefined =>
  selectEntity(state.virtualSteps, virtualStepId)?.present;

export const selectVirtualStepListItems = createSelector(
  (state: RootState) => selectVirtualStepsFolder(state),
  (state: RootState) => state.designer.selectedVirtualStepsFolderId,
  (virtualStepsFolder, selectedVirtualStepsFolderId) => {
    if (
      selectedVirtualStepsFolderId === undefined ||
      selectedVirtualStepsFolderId === ALL_NODES_ID
    ) {
      return getFolderFileNodes(virtualStepsFolder);
    }

    if (selectedVirtualStepsFolderId === ROOT_NODE_ID) {
      return getOnlyFilesInFolder(virtualStepsFolder);
    }

    const node = findNode(selectedVirtualStepsFolderId, virtualStepsFolder);

    if (node) {
      return getOnlyFilesInFolder(node.children);
    }

    return [];
  }
);

export const selectSelectedFolderPath = createSelector(
  (state: RootState) => selectVirtualStepsFolder(state),
  (state: RootState) => state.designer.selectedVirtualStepsFolderId,
  (virtualStepsFolder, selectedVirtualStepsFolderId) => {
    const node = findNode(selectedVirtualStepsFolderId, virtualStepsFolder);

    if (node) {
      return node.path;
    }
  }
);

export const selectVirtualStepsSlice = (
  state: RootState
): Redoable<VirtualStep>[] => state.virtualSteps;

export const selectPresentVirtualSteps = createSelector(
  selectVirtualStepsSlice,
  (steps: GenericState<VirtualStep>) => steps.map((step) => step.present)
);

const CATEGORY_SEPARATOR = "/";

const getVirtualStepPath = (step: VirtualStep): string | undefined => {
  const splitStepPath = split(serializeId(step.id) || "").slice(0, -1);

  if (splitStepPath.length === 0) {
    return undefined;
  }

  return splitStepPath.join(CATEGORY_SEPARATOR);
};

export type VirtualStepsByCategory = {
  categorizedSteps: Record<string, VirtualStep[]>;
  uncategorizedSteps: VirtualStep[];
};

const categorizeVirtualSteps = (
  steps: VirtualStep[]
): VirtualStepsByCategory => {
  const categorizedSteps: VirtualStepsByCategory["categorizedSteps"] = {};
  const uncategorizedSteps: VirtualStepsByCategory["uncategorizedSteps"] = [];

  steps.forEach((step) => {
    const category = getVirtualStepPath(step);

    if (!category) {
      uncategorizedSteps.push(step);
      return;
    }

    if (!categorizedSteps[category]) {
      categorizedSteps[category] = [];
    }

    categorizedSteps[category].push(step);
  });

  return {
    categorizedSteps,
    uncategorizedSteps,
  };
};

const selectStepsWithExistingFile = (steps: VirtualStep[]): VirtualStep[] =>
  steps.filter(({ id }) => Boolean(serializeId(id)));

export const selectVirtualStepsAsTree = createSelector(
  selectPresentVirtualSteps,
  (allSteps: VirtualStep[]): StepTypeEntityModel[] => {
    const { categorizedSteps, uncategorizedSteps } = categorizeVirtualSteps(
      selectStepsWithExistingFile(allSteps)
    );

    const categories = Object.entries(categorizedSteps).sort(([a], [b]) => {
      const aDepth = a.split(CATEGORY_SEPARATOR).length;
      const bDepth = b.split(CATEGORY_SEPARATOR).length;

      if (aDepth === bDepth) return 0;
      return aDepth > bDepth ? 1 : -1;
    });

    const baseGroupCategories = categories.filter(
      ([category]) => category.split(CATEGORY_SEPARATOR).length === 1
    );
    const baseGroup: StepTypeEntityModel = {
      id: "virtualSteps",
      text: "Virtual steps",
      parent: 0,
      data: {
        children: [
          ...baseGroupCategories.map(([category]) =>
            mapFileNode(category, category, FileType.Directory)
          ),
          ...uncategorizedSteps.map((node) =>
            mapFileNode(node.id, selectVirtualStepName(node))
          ),
        ],
        count: baseGroupCategories.length + uncategorizedSteps.length,
        type: FileType.Directory,
        isLast: uncategorizedSteps.length < 1,
        parent: null,
      },
    };

    const tree: StepTypeEntityModel[] = [baseGroup];

    categories.forEach(([category, entries], categoryIndex) => {
      const parts = category.split(CATEGORY_SEPARATOR);

      let parent = baseGroup.id;
      let dataParent = null;

      const isBaseGroupCategory = parts.length === 1;

      if (!isBaseGroupCategory) {
        parent = parts.slice(0, -1).join(CATEGORY_SEPARATOR);
        dataParent = tree.find(({ id }) => id === parent)!;
      }

      const childCategories = categories.filter(([otherCategory]) => {
        const otherParts = otherCategory.split(CATEGORY_SEPARATOR);
        const categoryAsParent = `${category}/`;
        return (
          otherCategory.startsWith(categoryAsParent) &&
          otherParts.length === parts.length + 1
        );
      });

      const isParentGroupLast =
        categories.length + uncategorizedSteps.length === categoryIndex + 1;

      const children = [
        ...entries.map((node) =>
          mapFileNode(node.id, selectVirtualStepName(node))
        ),
        ...childCategories.map(([category]) =>
          mapFileNode(category, category, FileType.Directory)
        ),
      ];

      const parentGroup: StepTypeEntityModel = {
        id: category,
        text: parts[parts.length - 1],
        parent,
        data: {
          children,
          count: children.length,
          type: FileType.Directory,
          isLast: isParentGroupLast,
          parent: dataParent,
        },
      };

      const parentIndex = tree.findIndex((treeNode) =>
        isBaseGroupCategory
          ? treeNode.id === parent
          : treeNode.id === baseGroup.id
      );
      const afterParent = parentIndex + 1;

      // guarantee folders have priority ordering
      tree.splice(afterParent, 0, parentGroup);

      entries.forEach((entry, index) => {
        tree.splice(afterParent + (index + 1), 0, {
          id: entry.id,
          text: selectVirtualStepName(entry),
          parent: parentGroup.id,
          data: {
            type: FileType.File,
            isLast: entries.length === index + 1,
            parent: parentGroup,
            iconColor: generatedDimHexColor(category),
          },
        });
      });
    });

    uncategorizedSteps.forEach((step, index) =>
      tree.push({
        id: step.id,
        text: selectVirtualStepName(step),
        parent: baseGroup.id,
        data: {
          type: FileType.File,
          isLast: uncategorizedSteps.length === index + 1,
          parent: baseGroup,
          iconColor: "#7681a2",
        },
      })
    );

    return tree;
  }
);

export const selectVirtualStepsPaths = createSelector(
  (state: RootState) => selectNodes(state, "virtualSteps", ALL_NODES_ID),
  (nodes) =>
    nodes.reduce<Record<string, string>>(
      (before, { id, path }) => ({ ...before, [id]: omitExtension(path) }),
      {}
    )
);

export const selectVirtualStepName = (step: VirtualStep): string =>
  step.displayName || step.fileName;
