import React, { useCallback, useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ButtonComponent from "@sapiens-digital/ace-designer-app/app/components/ButtonComponent";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { useAppDispatch } from "@sapiens-digital/ace-designer-app/app/store";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { setUsername } from "@sapiens-digital/ace-designer-app/app/store/designer/reducers";

import { getUsernameFromToken } from "./JWTTokenUtils";
import { useToken } from "./UseToken.hook";

const KEYCLOAK_AUTH_PATH = "/auth/keycloak";

export const LoginPopup: React.FC = () => {
  const { token, clearToken } = useToken();
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setUsername(getUsernameFromToken(token)));
  }, [dispatch, token]);

  useEffect(() => {
    const onFailedAuthForRequest = (): void => {
      setOpen(true);
      clearToken();
    };

    window.addEventListener("authfail", onFailedAuthForRequest);
    return () => {
      window.removeEventListener("authfail", onFailedAuthForRequest);
    };
  }, [clearToken]);

  const login = useCallback((): void => {
    window.open(process.env.REACT_APP_DEV_API_BASE_URL + KEYCLOAK_AUTH_PATH);
    setOpen(false);
  }, []);

  const onClose = () => setOpen(false);

  return (
    <Dialog open={open && !token} onClose={onClose}>
      <DialogTitle>Designer server login</DialogTitle>
      <DialogContent>You must be logged in to continue</DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <ButtonComponent variant="text" onClick={onClose}>
          Cancel
        </ButtonComponent>
        <ButtonComponent variant="contained" onClick={login}>
          Open login page in new window
        </ButtonComponent>
      </DialogActions>
    </Dialog>
  );
};
