import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {
  container,
  symbols,
} from "@sapiens-digital/ace-designer-app/app/services";
import { resetRefRegistry } from "@sapiens-digital/ace-designer-app/app/services/references";
import { store } from "@sapiens-digital/ace-designer-app/app/store";
import { initializeDesignerAction } from "@sapiens-digital/ace-designer-app/app/store/designer/actions";
import { createAppender } from "@sapiens-digital/ace-designer-app/app/utils/logMemoryAppender";

import { executeFlow } from "./services/devApis";
import {
  getEnvVariables,
  loadDesignerSettings,
  loadWorkspaceSettings,
  saveDesignerSettings,
  saveWorkspaceSettings,
} from "./services/settings";
import {
  getEnvironment,
  getWorkspaceFS,
  getWorkspaceHttpClient,
} from "./services/workspace";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "./index.css";

container.bind(symbols.LoadDesignerSettings).toFunction(loadDesignerSettings);
container.bind(symbols.SaveDesignerSettings).toFunction(saveDesignerSettings);
container.bind(symbols.LoadWorkspaceSettings).toFunction(loadWorkspaceSettings);
container.bind(symbols.SaveWorkspaceSettings).toFunction(saveWorkspaceSettings);
container.bind(symbols.GetWorkspaceFS).toFunction(getWorkspaceFS);
container.bind(symbols.GetEnvironment).toFunction(getEnvironment);
container.bind(symbols.EmitAppEvent).toFunction(() => void {});
container.bind(symbols.ExecuteFlow).toFunction(executeFlow);
container
  .bind(symbols.GetWorkspaceHttpClient)
  .toFunction(getWorkspaceHttpClient);
container.bind(symbols.GetEnvironmentVariables).toFunction(getEnvVariables);

createAppender();

resetRefRegistry();
store.dispatch(initializeDesignerAction());

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Provider>,
  document.getElementById("ace-root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
