import { utils } from "@rjsf/core";
import {
  mapPrimitiveValuesOfObject,
  Steps,
} from "@sapiens-digital/ace-designer-common";
import updateStepConfigWithCustomDefault from "@sapiens-digital/ace-designer-common/lib/helpers/stepHelper";
import { JSONSchema7 } from "json-schema";
import isString from "lodash/isString";

import {
  Flow,
  SerializedFlow,
  SerializedStep,
  Step,
  VirtualStep,
} from "../model";
import { Schema } from "../model/schemas";
import { Workspace } from "../model/workspace";
import { nameSelector } from "../store/flows/selectors";

import { removeUsageOfEntity, updateEntityIndexes } from "./indexing/indexer";
import {
  DeleteEntity,
  GetEntity,
  MoveEntity,
  SaveEntity,
} from "./entityService.types";
import {
  deleteFile,
  getFileDisplayName,
  moveFile,
  readFile,
  saveFile,
} from "./fs-utils";
import {
  deserializeChildId,
  getDeserializedId,
  serializeId,
} from "./references";
import { createVirtualStepInstanceSchema } from "./virtualSteps";
import { getContentRoot } from "./workspace";

const REGEX_UUID_V4 = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

/**
 * Obtains the flow from workspace
 * @param id flow id
 * @param workspace workspace
 * @returns flow stored in file system
 */
export const getFlow: GetEntity<Flow> = async (id, workspace) => {
  try {
    const fileFlow = (await readFile(id, workspace, "flows")) as SerializedFlow;
    removeUsageOfEntity(id, "flows");
    return deserializeFlow(id, fileFlow, workspace);
  } catch (e) {
    console.error(e);
    throw new Error(`Flow with the id "${id}" can not be retrieved`);
  }
};

/**
 * Saves flow to workspace
 * @param flow dynamic flow to save
 * @param workspace workspace
 * @param targetPath
 * @returns saved flow
 */
export const saveFlow: SaveEntity<Flow> = async (
  flow,
  workspace,
  targetPath
) => {
  const name = nameSelector(flow);

  try {
    const cleanFlow = serializeFlow(flow);

    await saveFile(flow.id, workspace, "flows", cleanFlow, name, targetPath);

    return flow;
  } catch (e) {
    console.error(e);
    throw new Error(`Flow "${name}" can not be saved`);
  }
};

export const moveFlow: MoveEntity<Flow> = async (id, workspace, targetPath) => {
  try {
    await moveFile(id, workspace, "flows", targetPath);
  } catch (e) {
    console.error(e);
    throw new Error(`Flow with the id "${id}" can not be moved`);
  }
};

/**
 * Deletes flow from workspace by id
 * @param id flow id
 * @param workspace workspace
 * @returns id of deleted flow it operation was successful
 */
export const deleteFlow: DeleteEntity = async (id, workspace) => {
  try {
    await deleteFile(id, workspace, "flows");
    removeUsageOfEntity(id, "flows");
  } catch (e) {
    console.error(e);
    throw new Error(`Flow with the id "${id}" can not be deleted`);
  }
};

function getStepName(fileStep: SerializedStep, steps: Step[]) {
  const nameCandidate = fileStep.name ?? fileStep.stepType;
  let finalName = nameCandidate;
  let nameIndex = 1;
  const stepNameExists = (stepName: string): boolean =>
    steps.find((s) => s.name === stepName) !== undefined;

  while (stepNameExists(finalName)) {
    nameIndex++;
    finalName = `${nameCandidate} ${nameIndex}`;
  }

  return finalName;
}

function deserializeStepConfig(
  config: SerializedStep["config"],
  workspace: Workspace,
  flowId?: string
): Step["config"] {
  return mapPrimitiveValuesOfObject(config ?? {}, (val: unknown) => {
    const deserializedPath =
      isString(val) &&
      val.endsWith(".yaml") &&
      getDeserializedId(getContentRoot(workspace, "flows"), val);

    if (deserializedPath && flowId) {
      updateEntityIndexes(deserializedPath, flowId, "flows");
    }

    return deserializedPath || val;
  });
}

function serializeStepConfig(
  config: Step["config"],
  flowId?: string
): SerializedStep["config"] {
  return mapPrimitiveValuesOfObject(config ?? {}, (val: unknown) => {
    const flowPath =
      isString(val) && val.match(REGEX_UUID_V4) && serializeId(val);

    if (flowPath && flowId) {
      updateEntityIndexes(val as string, flowId, "flows");
    }

    return flowPath || val;
  });
}

export function deserializeSteps(
  fileSteps: SerializedStep[],
  flowId: string,
  workspace: Workspace
): Step[] {
  if (!fileSteps) {
    return [];
  }

  const steps: Step[] = [];

  for (const fileStep of fileSteps) {
    const name = getStepName(fileStep, steps);
    const id = serializeId(flowId)
      ? deserializeChildId(name, flowId)
      : flowId + "-" + name;
    const step = {
      ...fileStep,
      id: id,
      name: name,
      stepType: fileStep.stepType,
      description: fileStep.description,
      condition: fileStep.condition,
      config: deserializeStepConfig(fileStep.config, workspace, flowId),
    };

    updateStepConfigWithCustomDefault(step);
    steps.push(step);
  }

  return steps;
}

export const serializeSteps = (
  steps: Step[],
  flowId?: string
): SerializedStep[] => {
  if (flowId) {
    removeUsageOfEntity(flowId, "flows");
  }

  return steps.map((step) => {
    const { id, ...other } = step;

    const result: SerializedStep = {
      ...other,
      name: step.name,
      stepType: step.stepType,
      description: step.description ?? "",
      condition: step.condition ?? "",
      config: serializeStepConfig(step.config, flowId),
    };

    return result;
  });
};

export const deserializeFlow = (
  id: string,
  fileFlow: SerializedFlow,
  workspace: Workspace,
  newFlowName?: string
): Flow => {
  const fileName = newFlowName || getFileDisplayName(id, workspace, "flows");
  return {
    id,
    name: fileName!,
    description: fileFlow.description,
    steps: deserializeSteps(fileFlow.steps, id, workspace),
    tags: fileFlow.tags,
    sampleInputSchema: fileFlow.sampleInputSchema ?? "",
    sampleData: fileFlow.sampleData ?? {},
    resultPath: fileFlow.resultPath,
  };
};

export const serializeFlow = (flow: Flow): SerializedFlow => {
  const result: SerializedFlow = {
    tags: flow.tags,
    sampleInputSchema: flow.sampleInputSchema ?? "",
    sampleData: flow.sampleData ?? {},
    description: flow.description ?? "",
    steps: serializeSteps(flow.steps, flow.id),
  };

  if (flow.resultPath) {
    result.resultPath = flow.resultPath;
  }

  return result;
};

export const initFlowReferences = async (
  ids: string[],
  workspace: Workspace
): Promise<void> => {
  for (const id of ids) {
    await getFlow(id, workspace);
  }
};

export const createVirtualStepInstance = (
  stepId: string,
  virtualStep: VirtualStep,
  virtualStepSchema: Schema | undefined
): Step => {
  const virtualStepId = serializeId(virtualStep.id) ?? virtualStep.id;
  const { jsonSchema } = createVirtualStepInstanceSchema(
    virtualStep,
    virtualStepSchema
  );
  const step = utils.getDefaultFormState(jsonSchema as JSONSchema7, {
    id: stepId,
    virtualStepId,
    stepType: Steps.VIRTUAL,
  }) as Step;

  return step;
};
