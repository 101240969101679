import { useCallback, useEffect, useState } from "react";

import { clearTokenFromLocalStore, getTokenFromLocalStorage } from "./utils";

/**
 * Hook to get the Auth token from local storage and listen for token changes
 */
export const useToken = (): {
  token: string | null;
  clearToken: () => void;
} => {
  const [token, setToken] = useState(getTokenFromLocalStorage());

  const onStorageChange = useCallback((event: StorageEvent): void => {
    if (event.key === "Authorization") {
      setToken(event.newValue);
    }
  }, []);

  const clearToken = useCallback(() => {
    clearTokenFromLocalStore();
  }, []);

  useEffect(() => {
    window.addEventListener("storage", onStorageChange);
    return () => {
      window.removeEventListener("storage", onStorageChange);
    };
  }, [onStorageChange]);

  return { token, clearToken };
};
