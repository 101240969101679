import jwtDecode from "jwt-decode";

type JWTToken = {
  data: {
    date: number;
    username: string;
    roles: string[];
  };
  iat: number;
  exp: number;
};

const extractJWTData = (newToken: string): JWTToken | null => {
  try {
    return jwtDecode<JWTToken>(newToken);
  } catch (e) {
    return null;
  }
};

export const getUsernameFromToken = (
  newToken: string | null
): string | null => {
  if (!newToken) {
    return null;
  }

  return extractJWTData(newToken)?.data.username || null;
};
