const DEPRECATED_REF_PREFIX = "#/components/schemas/";

type RefArray = string[];

export const updateDeprecatedRefFormatInSchema = (
  schema: unknown,
  pathToSchemasDirectory: string,
  onlyUpdateSpecificRefs?: RefArray
): unknown => {
  const result = JSON.stringify(schema, (key, value) => {
    if (key !== "$ref") return value;

    if (
      typeof value === "string" &&
      value.startsWith(DEPRECATED_REF_PREFIX) &&
      value.length > DEPRECATED_REF_PREFIX.length
    ) {
      const schemaName = value.slice(DEPRECATED_REF_PREFIX.length);

      if (
        onlyUpdateSpecificRefs &&
        !onlyUpdateSpecificRefs.includes(schemaName)
      ) {
        return value;
      }

      return `${pathToSchemasDirectory}/${schemaName}.yaml`;
    }

    return value;
  });
  return JSON.parse(result);
};
