import { Workspace } from "../../model/workspace";
import { getDeserializedId } from "../references";
import { getContentRoot } from "../workspace";

import { removeUsageOfEntity, updateEntityIndexes } from "./indexer";
import { IndexableEntityTypes } from "./indexer";

export const updateFlowUsageByEntity = (
  id: string,
  flowName: string,
  entityType: IndexableEntityTypes,
  workspace: Workspace
): void => {
  removeUsageOfEntity(id, entityType);

  let flowPath = flowName;

  if (!flowName.endsWith(".yaml")) {
    flowPath = flowName + ".yaml";
  }

  const flowId = getDeserializedId(
    getContentRoot(workspace, "flows"),
    flowPath
  );

  if (flowId) {
    updateEntityIndexes(flowId, id, entityType);
  }
};
