import { createAsyncThunk } from "@reduxjs/toolkit";

import { Flow } from "../../model/flow";
import { NotificationOptionalKey } from "../../model/notification";
import { Workspace } from "../../model/workspace";
import {
  deleteFlow,
  getFlow,
  moveFlow,
  saveFlow,
  serializeFlow,
} from "../../services/flows";
import {
  pushAllEntityChangesToGit,
  pushDeletedFolderToGit,
} from "../../services/git-utils";
import { getSchema } from "../../services/schemas";
import { generateSampleObjectFromSchema } from "../../utils/jsonSchemaFaker";
import type { RootState } from "..";
import { addNotification } from "../designer/actions";
import { selectEntityId } from "../references/selectors";
import {
  addEntityToGitActionCreator,
  deleteAndPushActionCreator,
  deleteEntityActionCreator,
  deleteFolderActionCreator,
  moveAndPushActionCreator,
  moveEntityActionCreator,
  moveFolderActionCreator,
  openEntityActionCreator,
  refreshEntitiesActionCreator,
  renameFolderActionCreator,
  storeAndPushActionCreator,
  storeEntityActionCreator,
  storeFolderActionCreator,
} from "../utils/actionFactory";
import {
  assertFlow,
  assertSettings,
  assertWorkspace,
} from "../utils/assertEntities";
import { processExecuteFlow } from "../utils/executeFlowHelper";
import { refreshAndSetupWorkspaceAction } from "../workspaces/actions";
import { selectSelectedWorkspace } from "../workspaces/selectors";

import { nameSelector, selectFlow } from "./selectors";
import { name } from "./sliceName";

export const openFlowAction = openEntityActionCreator(name, getFlow);

export const loadFlowAction = openEntityActionCreator(name, getFlow, "load");

export const storeFlowAction = storeEntityActionCreator<Flow>(
  name,
  saveFlow,
  "flows"
);

export const moveFlowAction = moveEntityActionCreator<Flow>(
  name,
  moveFlow,
  "flows"
);

export const storeFlowFolderAction = storeFolderActionCreator(name, "flows");

export const renameFlowFolderAction = renameFolderActionCreator(name, "flows");

export const moveFlowFolderAction = moveFolderActionCreator(name, "flows");

export const overwriteFlowFolderAction = moveFolderActionCreator(
  name,
  "flows",
  "overwrite"
);

export const deleteFlowAction = deleteEntityActionCreator(
  name,
  deleteFlow,
  "flows"
);

export const deleteFlowFolderAction = deleteFolderActionCreator(name, "flows");

export const addFlowToGitAction = addEntityToGitActionCreator(name, "flows");

export const addRemovedFlowFolderToGitAction = addEntityToGitActionCreator(
  name,
  "flows",
  pushDeletedFolderToGit
);

export const storeAndPushFlowAction = storeAndPushActionCreator<Flow>(
  name,
  nameSelector,
  storeFlowAction,
  addFlowToGitAction
);

export const moveAndPushFlowAction = moveAndPushActionCreator<Flow>(
  name,
  moveFlowAction,
  addFlowToGitAction
);

export const deleteAndPushFlowAction = deleteAndPushActionCreator(
  name,
  deleteFlowAction,
  addFlowToGitAction
);

export const deleteAndPushFlowFolderAction = deleteAndPushActionCreator(
  name,
  deleteFlowFolderAction,
  addRemovedFlowFolderToGitAction
);

export const refreshFlowsAction = refreshEntitiesActionCreator(
  name,
  getFlow,
  serializeFlow
);

export const generateDataForInputSchemaAction = createAsyncThunk<
  Flow,
  { flowId: string; sampleInputSchema: string; workspace: Workspace },
  { state: RootState }
>(
  `${name}/generateDataForInputSchema`,
  async (
    { flowId, sampleInputSchema, workspace },
    { getState }
  ): Promise<Flow> => {
    const flow = selectFlow(getState(), flowId);
    assertFlow(flow);

    if (!sampleInputSchema) {
      return {
        ...flow,
        sampleInputSchema,
      };
    }

    let sampleData;

    try {
      const schemaId = selectEntityId(sampleInputSchema, "schemas")(getState());

      if (schemaId) {
        const { file } = await getSchema(schemaId, workspace);
        sampleData = generateSampleObjectFromSchema(file);
      } else {
        sampleData = {};
      }
    } catch (e) {
      sampleData = {};
    }

    return {
      ...flow,
      sampleInputSchema,
      sampleData,
    };
  }
);

const NOTIFY_IMPORT_SUCCESS: NotificationOptionalKey = {
  message: "Flow imported successfully.",
  variant: "success",
};

export const processImportFlowSaveAction = createAsyncThunk<
  void,
  { flow: Flow; targetPath: string },
  { state: RootState }
>(
  `${name}/processImportFlowSave`,
  async ({ flow, targetPath }, { getState, dispatch }) => {
    const state = getState();
    const currentWorkspace = selectSelectedWorkspace(state);
    assertWorkspace(currentWorkspace);
    assertSettings(state.settings);

    await saveFlow(flow, currentWorkspace, targetPath);
    await pushAllEntityChangesToGit(
      currentWorkspace,
      state.settings.repositoryUrl,
      state.settings.repositoryToken,
      state.settings.repositoryRoot,
      false,
      state.settings.repositoryUsername
    );
    await dispatch(refreshAndSetupWorkspaceAction());
    dispatch(addNotification(NOTIFY_IMPORT_SUCCESS));
  }
);

export const testStepAction = createAsyncThunk<
  { stepId: string; result: unknown },
  { flowId: string; stepId: string; inputDoc: Record<string, unknown> },
  { state: RootState }
>(
  `${name}/testStep`,
  async (
    { flowId, stepId, inputDoc },
    { getState }
  ): Promise<{ result: unknown; stepId: string }> => {
    const state = getState();
    const flow = selectFlow(state, flowId);
    const currentWorkspace = selectSelectedWorkspace(state);
    assertFlow(flow);

    const step = flow?.steps.find((stp) => stp.id === stepId);

    if (!step) {
      throw new Error("step could not be found");
    }

    const result = await processExecuteFlow(
      {
        flow: {
          name: flow.name,
          steps: [
            {
              ...step,
              config: step.config ?? {},
            },
          ],
        },
        doc: inputDoc,
        args: {
          debugMode: true,
        },
      },
      state.settings,
      currentWorkspace
    );

    return { result, stepId };
  }
);

export const testFlowAction = createAsyncThunk<
  { flowId: string; result: unknown },
  { flowId: string; inputDoc: Record<string, unknown> },
  { state: RootState }
>(
  `${name}/testFlow`,
  async (
    { flowId, inputDoc },
    { getState }
  ): Promise<{ flowId: string; result: unknown }> => {
    const state = getState();
    const flow = selectFlow(state, flowId);

    const currentWorkspace = selectSelectedWorkspace(state);
    assertFlow(flow);

    const result = await processExecuteFlow(
      {
        flow: {
          name: flow.name,
          steps: flow.steps.map((step) => ({
            ...step,
            config: step.config ?? {},
          })),
          resultPath: flow.resultPath,
        },
        doc: inputDoc,
      },
      state.settings,
      currentWorkspace
    );

    return { flowId, result };
  }
);
