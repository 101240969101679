import React from "react";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { TransitionProps as MuiTransitionProps } from "@mui/material/transitions";

import { aceTheme } from "../ace-theme";
import ButtonComponent, { ButtonComponentProps } from "../ButtonComponent";

export interface ConfirmationDialogProps {
  children?: React.ReactNode;
  open: boolean;
  title?: string;
  loading?: boolean;
  renderOkButton?: (defaultProps: ButtonComponentProps) => JSX.Element;
  renderCancelButton?: (defaultProps: ButtonComponentProps) => JSX.Element;
  handleClose?: () => void;
  TransitionProps?: MuiTransitionProps;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (
  props: ConfirmationDialogProps
) => {
  const {
    children,
    loading,
    open,
    renderOkButton,
    renderCancelButton,
    title,
    handleClose,
    TransitionProps,
  } = props;

  const defaultPropsCancelButton: ButtonComponentProps = {
    variant: "text",
    text: "cancel",
  };

  const defaultPropsOkButton: ButtonComponentProps = {
    variant: "contained",
    loading,
    text: "ok",
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      data-testid="confirmation-dialog-container"
      TransitionProps={TransitionProps}
    >
      {title && (
        <DialogTitle
          sx={{
            fontWeight: 500,
            fontSize: 16,
            textTransform: "uppercase",
          }}
        >
          {title}
        </DialogTitle>
      )}
      <DialogContent
        sx={{
          paddingTop: "30px",
          paddingBottom: "20px",
          fontWeight: 400,
          fontSize: 16,
          color: aceTheme.palette.darkGrey,
        }}
      >
        {children}
      </DialogContent>
      <DialogActions>
        {renderCancelButton?.(defaultPropsCancelButton) || (
          <ButtonComponent {...defaultPropsCancelButton} />
        )}
        {renderOkButton?.(defaultPropsOkButton) || (
          <ButtonComponent {...defaultPropsOkButton} />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
