import React from "react";
import { Designer } from "@sapiens-digital/ace-designer-app/app/components/Designer";

import logo from "./assets/logo.png";
import { LoginPopup } from "./login/LoginPopup";
import { LoginTracker } from "./login/LoginTracker";

function App(): React.ReactElement {
  return (
    <React.Fragment>
      <LoginTracker />
      <LoginPopup />
      <Designer logo={logo} />
    </React.Fragment>
  );
}

export default App;
