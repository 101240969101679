import { createAsyncThunk } from "@reduxjs/toolkit";

import { Api } from "../../model/api";
import {
  deleteApi,
  getApi,
  mapSerializedApi,
  saveApi,
} from "../../services/apis";
import { RootState } from "../index";
import {
  addEntityToGitActionCreator,
  deleteAndPushActionCreator,
  deleteEntityActionCreator,
  openEntityActionCreator,
  refreshEntitiesActionCreator,
  storeAndPushActionCreator,
  storeEntityActionCreator,
} from "../utils/actionFactory";
import { assertWorkspace } from "../utils/assertEntities";
import { selectEntity } from "../utils/redoableSliceSelectors";
import { selectFolder, selectSelectedWorkspace } from "../workspaces/selectors";

import { nameSelector } from "./selectors";
import { name } from "./sliceName";

export const openApiAction = openEntityActionCreator(name, getApi);

// TODO: modify it not to call update for the entire api folder and add files like headers, query params, etc.
export const storeApiAction = storeEntityActionCreator<Api>(
  name,
  saveApi,
  "apis"
);

export const deleteApiAction = deleteEntityActionCreator(
  name,
  deleteApi,
  "apis"
);

export const addApiToGitAction = addEntityToGitActionCreator(name, "apis");

export const storeAndPushApiOperationsAction = createAsyncThunk<
  void,
  {
    apiId: string;
  },
  { state: RootState }
>(
  `${name}/storeOperationsAndPush`,
  async ({ apiId }, { dispatch, getState }) => {
    const apiFile = selectEntity(getState().apis, apiId);

    if (!apiFile) {
      throw new Error("No apiFile file present for id:" + apiId);
    }

    await dispatch(storeAndPushApiFile(apiFile.present));
  }
);

export const storeAndPushApiFile = storeAndPushActionCreator(
  name,
  nameSelector,
  storeApiAction,
  addApiToGitAction
);

export const deleteAndPushApi = deleteAndPushActionCreator(
  name,
  deleteApiAction,
  addApiToGitAction
);

export const refreshApisAction = refreshEntitiesActionCreator(
  name,
  getApi,
  mapSerializedApi
);

export const loadWorkspaceApisIntoState = createAsyncThunk(
  `${name}/loadWorkspaceApisIntoState`,
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;
    const currentWorkspace = selectSelectedWorkspace(state);
    assertWorkspace(currentWorkspace);
    const apisFolder = selectFolder(state, "apis");

    await dispatch(
      refreshApisAction({
        wsId: currentWorkspace.id,
        entityIds: apisFolder.map((api) => api.id),
      })
    );
  }
);
